import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { SingleRowLogos } from '@bestfit-team/bf-base-ui';

const EligibilityAssessmentLogos = ({datum}) => {
  const copy = useStaticQuery(graphql`
    query EligibilityAssessmentLogosQuery {
        myEligibilityAssessmentLogos: airtable(data: { Segment: {eq: "Supporters-eligibility-use"}}) {
          id
          data {
            header
            subheader
            subheader_2
            Images {
              data {
                file_name
                section_order
                alt_text
                url
              }
            }
          }
        }
    }
  `);

console.log(JSON.stringify(copy))
  return (
      <SingleRowLogos
        version={'Companies'}
        heroBackgroundColor={'var(--bf-core-teal)'}
        backgroundImageUrl={'https://best-fit.app/static/triangle-grey-25777a8336e851acd1255a9ef785a027.svg'}

        headingCopy={
          copy.myEligibilityAssessmentLogos.data.header 
        }
        subheadingCopy={
          copy.myEligibilityAssessmentLogos.data.subheader 
        }
        imageData={
          copy.myEligibilityAssessmentLogos.data.Images
        }
      />
  )
}

export default EligibilityAssessmentLogos;
