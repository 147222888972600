import React from "react";
// import { graphql, useStaticQuery } from 'gatsby';
import {Footer, GridTwo } from '@bestfit-team/bf-base-ui';
import Layout from "../../components/layout";
import EligibilityAssessmentHero from "../../sections/eligibility-assessment/EligibilityAssessmentHero";
import EligibilityAssessmentContactUs from "../../sections/eligibility-assessment/EligibilityAssessmentContact";
import EligibilityAssessmentLogos from "../../sections/eligibility-assessment/EligibilityAssessmentLogos";
import EligibilityAssessmentHowItWorks from "../../sections/eligibility-assessment/EligibilityAssessmentHowItWorks";

const EligibilityAssessment = ({ data }) => {
  console.log(data)
  return (
    <Layout
      pageTitle='Eligibility Assessment'
    >
      <EligibilityAssessmentHero />
      
      <GridTwo
        spansFullPage
        disableGutters 
        marginless
        heroBackgroundColor='var(--bf-core-teal)'
      >
        <p> </p>
      </GridTwo>

      <EligibilityAssessmentHowItWorks />
      <EligibilityAssessmentLogos />
      <EligibilityAssessmentContactUs />
      <Footer />
    </Layout>
  )
}

// export const query = graphql`
//   query EligibilityAssessmentPageContentQuery {
    
//   }
// `

export default EligibilityAssessment;